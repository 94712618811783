import { devMode } from "ui/utils";
import { ApolloClient, InMemoryCache } from "@apollo/client";

export const getContentfulGraphqlClient = () => {
  const env = devMode() ? "staging" : "master";
  const space = process.env.NEXT_PUBLIC_CONTENTFUL_SPACE!;
  const apiKey = process.env.NEXT_PUBLIC_CONTENTFUL_DELIVERY_API_KEY!;

  return new ApolloClient({
    uri: `https://graphql.contentful.com/content/v1/spaces/${space}/environments/${env}`,
    cache: new InMemoryCache(),
    headers: {
      Authorization: `Bearer ${apiKey}`,
    },
  });
};
