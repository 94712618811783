'use client';

import { tv } from 'tailwind-variants';
import ChevronDownSVG from 'ui/icons/chevron-down.svg';
import {
  FC,
  useState,
  Fragment,
  useContext,
  createContext,
  ComponentProps,
  PropsWithChildren,
  ExoticComponent,
} from 'react';
import { Transition } from '@headlessui/react';

const Context = createContext<{
  open: boolean;
  toggle: () => void;
}>({ open: false, toggle: () => {} });

const useCtx = () => useContext(Context);

const ul = tv({
  base: 'ml-4 flex flex-col gap-3 [&_ul]:my-3 transition-all duration-200',
});

const icon = tv({
  base: '[&_path]:stroke-black transition-transform',
  variants: {
    open: {
      true: 'rotate-180',
    },
  },
});

const Title: FC<PropsWithChildren> = ({ children }) => {
  const { open, toggle } = useCtx();
  return (
    <button
      onClick={toggle}
      className="w-full text-left font-bold flex justify-between items-center"
    >
      <span className="flex-1">{children}</span>
      <ChevronDownSVG className={icon({ open })} />
    </button>
  );
};

const ListItem: FC<
  PropsWithChildren &
    ComponentProps<'li'> & {
      as?: keyof JSX.IntrinsicElements | ExoticComponent;
    }
> = ({ children, as: As = 'li', ...props }) => (
  <As {...(props as unknown as any)}>{children}</As>
);

const ListRoot: FC<ComponentProps<'ul'>> = ({ children, className }) => {
  const { open } = useCtx();
  return (
    <Transition
      as="ul"
      appear
      show={open}
      unmount={false}
      enterTo="translate-y-0 h-auto"
      leaveTo="-translate-y-3 opacity-0"
      enterFrom="-translate-y-3 opacity-0"
      className={ul({ className })}
    >
      {children}
    </Transition>
  );
};

const List = Object.assign(ListRoot, { Item: ListItem });

const Root: FC<
  PropsWithChildren & { as?: keyof JSX.IntrinsicElements; defaultOpen?: boolean }
> = ({ children, defaultOpen = true, as: As = Fragment }) => {
  const [open, setOpen] = useState(defaultOpen);
  const toggle = () => setOpen(pv => !pv);
  return (
    <Context.Provider value={{ open, toggle }}>
      <As>{children}</As>
    </Context.Provider>
  );
};

export const Menu = Object.assign(Root, { Title, List });
