import Link from 'next/link';
import { FC } from 'react';

export const HREF = '/quiz/behandlingsformer?from=akneprogrammet';

export const CreateAkneprogrammetCaseLink: FC<{
  children: any;
  className?: string;
}> = ({ children, className }) => {
  return (
    <Link href={HREF} className={className}>
      {children}
    </Link>
  );
};
