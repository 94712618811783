import Link from 'next/link';
import { FC } from 'react';

export const CreateCaseLink: FC<{
  children: any;
  className?: string;
  href?: string;
}> = ({ children, className, href = '/create-case/welcome' }) => {
  return (
    <Link href={href} className={className}>
      {children}
    </Link>
  );
};
