'use client';

import Link from 'next/link';
import styles from './styles.module.scss';
import KluttSVG from '@/public/klutt-1.svg';
import CloseSVG from 'ui/icons/sidebar/close.svg';
import HamburgerSVG from 'ui/icons/sidebar/hamburger.svg';
import CircleArrowRightSVG from 'ui/icons/sidebar/circle-arrow-right.svg';
import { VariantProps, tv } from 'tailwind-variants';
import {
  ComponentProps,
  FC,
  Fragment,
  RefObject,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { Transition } from '@headlessui/react';
import { Menu } from './Menu';
import { getContentfulGraphqlClient } from '@/clients/contentful.graphql';
import { gql, useQuery } from '@apollo/client';
import { MdArrowBackIos } from 'react-icons/md';
import { usePathname } from 'next/navigation';
import { CreateAkneprogrammetCaseLink } from '@/components/Links/CreateAkneprogrammetCaseLink';
import { CreateCaseLink } from '@/components/Links/CreateCaseLink';

const NAVBAR_HEIGHT_PX = 40;

const logo = tv({
  base: 'flex-shrink-0',
  variants: {
    inverted: {
      true: 'invert-[80%]',
      false: 'fill-black',
    },
    mobile: {
      true: 'invert-[80%]',
      false: 'fill-black',
    },
  },
  defaultVariants: {
    inverted: false,
    mobile: false,
  },
});

const title = tv({
  base: 'flex justify-start items-center text-gray-200',
  variants: {
    inverted: {
      true: 'text-gray-900',
    },
  },
  defaultVariants: {
    inverted: false,
  },
});
const titleText = tv({
  base: 'font-medium text-[0.9rem] ml-0.5 whitespace-nowrap ',
  variants: {
    inverted: {
      true: 'text-gray-700',
    },
  },
  defaultVariants: {
    inverted: false,
  },
});
const Title: FC<ComponentProps<'div'> & VariantProps<typeof title>> = ({
  inverted,
  className,
  ...props
}) => (
  <div className={title({ className, inverted })} {...props}>
    <KluttSVG width={20} height={20} className={logo({ inverted })} {...props} />
    <Link href="/" className={titleText({ inverted })}>
      dr hud
    </Link>
  </div>
);

const startVarient = tv({
  base: `${styles.navButton} bg-opacity-75 bg-[#fde68a] flex justify-center whitespace-nowrap items-center lg:w-[14%]`,
  variants: {
    hideButton: {
      true: 'opacity-0',
      false: 'opacity-100',
    },
  },
});
const StartTreatmentButton: FC<{ className?: string }> = ({ className }) => {
  const pathname = usePathname();
  const hideButton = pathname == '/create-case/welcome';
  return (
    <>
      <CreateCaseLink className={startVarient({ hideButton: hideButton, className })}>
        Starta ärende
      </CreateCaseLink>
    </>
  );
};

const SeekCareButton: FC<{ className?: string }> = ({ className }) => {
  const pathname = usePathname();
  const hideButton = pathname == '/create-case/welcome';
  return (
    <>
      <CreateAkneprogrammetCaseLink
        className={startVarient({ hideButton: hideButton, className })}
      >
        Sök vård
      </CreateAkneprogrammetCaseLink>
    </>
  );
};
const AkneprogrammetTitle: FC<ComponentProps<'div'> & VariantProps<typeof title>> = ({
  inverted,
  className,
  ...props
}) => {
  const pathname = usePathname();

  const backToDrHud = /akneprogrammet\/?$/i.test(pathname);

  const href = backToDrHud ? '/' : '/akneprogrammet';
  const label = backToDrHud ? <>till Dr Hud</> : <>Tillbaka</>;

  return (
    <div className={title({ inverted, className })} {...props}>
      <div className="flex">
        <Link href={href} className="font-thin text-xs  flex items-center">
          <MdArrowBackIos size={14} />
          <span className="whitespace-nowrap">{label}</span>
        </Link>
      </div>
    </div>
  );
};

const links = tv({
  base: 'overflow-hidden [&_a]:truncate flex flex-1 gap-5 text-[0.8rem] sm:text-[0.7rem] lg:text-[0.8rem] text-gray-200 font-light pr-2 md:pr-0 items-center  md:justify-center tracking-wider',
  variants: {
    inverted: {
      true: 'text-gray-900',
    },
    backButtonHidden: {
      true: 'justify-start',
      false: 'justify-end',
    },
  },
  defaultVariants: {
    inverted: false,
  },
});
const subPages = tv({
  base: 'flex flex-1 gap-5 text-gray-900 text-[0.8rem] sm:text-[0.7rem] lg:text-[0.8rem]  font-light  justify-end  tracking-wider mr-5',
});
const Links: FC<ComponentProps<'div'> & VariantProps<typeof links>> = ({
  inverted,
  className,
  ...props
}) => {
  const pathname = usePathname();

  return (
    <div className={links({ className, inverted })} {...props}>
      {pathname === '/' || pathname === '/create-case/welcome' ? (
        <>
          <Link className="hidden md:block focus:outline-none" href="#what-we-treat">
            Vårt erbjudande
          </Link>
          <Link className="hidden md:block focus:outline-none" href="#our-process">
            Så funkar Dr hud
          </Link>
          <Link className="hidden md:block focus:outline-none" href="/#patient-stories">
            Omdömen
          </Link>
          <Link className="hidden md:block focus:outline-none" href="#faq">
            Frågor & svar
          </Link>
          <Link href="/hudsjukdomar" className="hidden md:block focus:outline-none">
            Hudsjukdomar A-Ö
          </Link>
          <Link className="hidden md:block focus:outline-none" href="/akneprogrammet">
            Akneprogrammet
          </Link>
          <Link
            href="/onboarding/bankid"
            className="hidden md:block focus:outline-none"
          >
            Logga in
          </Link>
        </>
      ) : (
        <div className={subPages()}>
          <Link href="/hudsjukdomar" className="hidden md:block focus:outline-none">
            Hudsjukdomar
          </Link>
          <Link
            href="/onboarding/bankid"
            className="hidden md:block focus:outline-none"
          >
            Logga in
          </Link>
        </div>
      )}
    </div>
  );
};

const AkneprogrammetLinks: FC<
  ComponentProps<'div'> & VariantProps<typeof links> & VariantProps<typeof logo>
> = ({ mobile, inverted, backButtonHidden, className, ...props }) => (
  <div className={links({ className, inverted, backButtonHidden })} {...props}>
    <div className="flex">
      <KluttSVG
        width={20}
        height={20}
        className={logo({ inverted, mobile })}
        {...props}
      />
      <Link href="/akneprogrammet" className={titleText({ inverted })}>
        Akneprogrammet
      </Link>
    </div>
    <Link
      className="hidden md:block focus:outline-none "
      href="/akneprogrammet#Gör-vårt-självtest"
    >
      Gör vårt självtest
    </Link>
    <Link
      className="hidden md:block focus:outline-none"
      href="/akneprogrammet#akneprogrammet"
    >
      Akneprogrammet
    </Link>
    <Link
      className="hidden md:block focus:outline-none"
      href="/akneprogrammet/behandlingar-priser"
    >
      Behandlingar & priser
    </Link>
    <Link className="hidden md:block focus:outline-none" href="/akneprogrammet#doctors">
      Läkarteamet
    </Link>
    <Link
      className="hidden md:block focus:outline-none"
      href="/akneprogrammet#akneguiden"
    >
      Akneguiden
    </Link>
    <Link className="hidden md:block focus:outline-none" href="/akneprogrammet/om-oss">
      Om oss
    </Link>
  </div>
);

const Sidebar: FC<{ open: boolean; onClose?: () => void }> = ({ open, onClose }) => {
  const pathname = usePathname();
  const isAkneprogrammetPage = /akneprogrammet/gi.test(pathname);
  const clientRef = useRef(getContentfulGraphqlClient());
  const client = clientRef.current;
  const { loading, data } = useQuery(
    gql`
      query Q {
        contentCollection(where: { slug: "faqs" }) {
          items {
            sys {
              id
            }
            content {
              json
            }
          }
        }
        faqCollection {
          items {
            sys {
              id
            }
            question
          }
        }
      }
    `,
    { client },
  );
  if (loading) return <></>;
  const ids = data.contentCollection.items
    .at(0)
    .content.json.content.filter(item => item.nodeType === 'embedded-entry-block')
    .map(item => item.data.target.sys.id) as Array<string>;
  const faqs = data.faqCollection.items
    .filter(item => ids.includes(item.sys.id))
    .sort((a, b) => ids.indexOf(a.sys.id) - ids.indexOf(b.sys.id))
    .slice(0, 3);

  return (
    <Transition
      as="div"
      appear
      show={open}
      enterTo="translate-y-0"
      leaveTo="-translate-y-full"
      enterFrom="-translate-y-full"
      className="md:hidden bg-[#f5efd9] fixed top-0 left-0 w-full h-full z-50 transition-transform duration-200 flex flex-col overflow-auto"
    >
      <nav className={nav({ className: 'relative mb-auto' })}>
        <div className="mx-auto max-w-4xl h-[40px] w-full justify-between items-center flex pl-8">
          <Title inverted />
          <button
            onClick={onClose}
            className="h-full w-12 p-1 flex items-center justify-center md:hidden"
          >
            <CloseSVG className="w-6 h-6" />
          </button>
        </div>
      </nav>
      <div className="flex-1 flex flex-col px-8 py-12 gap-3 overflow-auto">
        <Menu>
          <Menu.List className="m-0">
            <Menu as="li" defaultOpen={!isAkneprogrammetPage}>
              <Menu.Title>Sök vård digitalt</Menu.Title>
              <Menu.List>
                <Menu.List.Item as={Fragment}>
                  <Link href="/starta-arende" onClick={onClose}>
                    Starta ärende
                  </Link>
                </Menu.List.Item>
                <Menu.List.Item as={Fragment}>
                  <Link href="/sa-funkar-drhud" onClick={onClose}>
                    Så funkar Dr Hud
                  </Link>
                </Menu.List.Item>
              </Menu.List>
            </Menu>
            <Menu as="li" defaultOpen={!isAkneprogrammetPage}>
              <Menu.Title>Fakta & råd</Menu.Title>
              <Menu.List>
                <Menu.List.Item as={Fragment}>
                  <Link href="/hudsjukdomar" onClick={onClose}>
                    Hudsjukdomar A-Ö
                  </Link>
                </Menu.List.Item>
                {/* <Menu.List.Item onClick={onClose}>Tips & artiklar</Menu.List.Item> */}
              </Menu.List>
            </Menu>
            <Menu as="li" defaultOpen={isAkneprogrammetPage}>
              <Menu.Title>Akneprogrammet</Menu.Title>
              <Menu.List>
                <Menu.List.Item as={Fragment}>
                  <Link href="/akneprogrammet" onClick={onClose}>
                    Akneprogrammet
                  </Link>
                </Menu.List.Item>
                <Menu.List.Item as={Fragment}>
                  <Link href="/akneprogrammet/behandlingar-priser" onClick={onClose}>
                    Behandlingar & priser
                  </Link>
                </Menu.List.Item>
                <Menu.List.Item as={Fragment}>
                  <Link href="/quiz/aknetestet" onClick={onClose}>
                    Gör vårt självtest
                  </Link>
                </Menu.List.Item>
                <Menu.List.Item as={Fragment}>
                  <Link href="/akneprogrammet/sa-funkar-det" onClick={onClose}>
                    Så funkar det
                  </Link>
                </Menu.List.Item>
                <Menu.List.Item as={Fragment}>
                  <Link href="/akneprogrammet/akneguiden" onClick={onClose}>
                    Akneguiden
                  </Link>
                </Menu.List.Item>
                <Menu.List.Item as={Fragment}>
                  <Link href="/akneprogrammet/vara-lakare" onClick={onClose}>
                    Läkarteamet
                  </Link>
                </Menu.List.Item>
                <Menu.List.Item as={Fragment}>
                  <Link href="/akneprogrammet/om-oss" onClick={onClose}>
                    Om oss
                  </Link>
                </Menu.List.Item>
              </Menu.List>
            </Menu>
            <Menu as="li" defaultOpen={false}>
              <Menu.Title>FAQ</Menu.Title>
              <Menu.List>
                {faqs.map(item => (
                  <Menu.List.Item key={item.sys.id} as={Fragment}>
                    <Link
                      href={`/#${item.sys.id}`}
                      className="truncate"
                      onClick={onClose}
                    >
                      {item.question}
                    </Link>
                  </Menu.List.Item>
                ))}
                <Menu.List.Item as={Fragment}>
                  <Link href="/faq" className="flex gap-1 font-bold" onClick={onClose}>
                    Se alla FAQs <CircleArrowRightSVG className="w-6" />
                  </Link>
                </Menu.List.Item>
              </Menu.List>
            </Menu>
            {/* <Menu.List.Item onClick={onClose}>Om Dr Hud</Menu.List.Item> */}
            <Menu.List.Item as={Fragment}>
              <Link href="/onboarding/bankid" className="font-bold" onClick={onClose}>
                Logga in
              </Link>
            </Menu.List.Item>
          </Menu.List>
        </Menu>
        <div className="flex-1" />
        <CreateCaseLink className="flex-shrink-0 h-12 bg-black text-white flex items-center justify-center">
          Starta ärende
        </CreateCaseLink>

        <a className="text-center" href="mailto:hello@drhud.se">
          hello@drhud.se
        </a>
      </div>
    </Transition>
  );
};

const Wrapper = forwardRef<
  HTMLDivElement,
  ComponentProps<'div'> & VariantProps<typeof Title> & VariantProps<typeof Links>
>(({ children, inverted, ...props }, ref) => {
  const [mounted, setMounted] = useState(false);
  const [open, setOpen] = useState(false);
  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);
  useEffect(() => {
    setMounted(true);
  }, []);
  return (
    <>
      <div ref={ref} className="overflow-hidden">
        <div className="mx-auto max-w-6xl h-[40px] w-full justify-between items-center flex pl-8">
          <Title inverted={inverted} />
          <Links inverted={inverted} />
          {children}
          <button
            onClick={onOpen}
            className={`h-full w-12 p-1 flex items-center justify-center md:hidden ${
              inverted ? 'bg-black/20' : 'bg-white/20'
            }`}
          >
            <HamburgerSVG className="fill-white w-8 h-8" />
          </button>
        </div>
      </div>
      {mounted &&
        createPortal(<Sidebar open={open} onClose={onClose} />, document.body)}
    </>
  );
});
Wrapper.displayName = 'Wrapper';
const AkneprogrammetWrapper = forwardRef<
  HTMLDivElement,
  ComponentProps<'div'> &
    VariantProps<typeof Title> &
    VariantProps<typeof Links> &
    VariantProps<typeof logo>
>(({ children, inverted, mobile, ...props }, ref) => {
  const pathname = usePathname();
  const behandlingsform = pathname == '/quiz/behandlingsformer';
  const [mounted, setMounted] = useState(false);
  const [open, setOpen] = useState(false);
  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);

  useEffect(() => {
    setMounted(true);
  }, []);
  return (
    <>
      <div ref={ref} className="overflow-hidden">
        <div className="md:mx-auto md:max-w-6xl md:h-[40px] md:w-full md:justify-between md:items-center md:flex  hidden  ">
          {!behandlingsform && <AkneprogrammetTitle inverted={inverted} />}
          <AkneprogrammetLinks inverted={inverted} />
          {children}
        </div>
        <div className="md:hidden h-[40px] w-full justify-between items-center flex bg-white pl-8">
          {!behandlingsform && <AkneprogrammetTitle className="text-black" />}
          <AkneprogrammetLinks
            className="text-black"
            mobile={true}
            backButtonHidden={behandlingsform}
          />
          {/* Explicitly pass inverted as false */}
          <button
            onClick={onOpen}
            className="h-full w-12 p-1 flex items-center justify-center bg-black/20"
          >
            <HamburgerSVG className="fill-white w-8 h-8" />
          </button>
        </div>
      </div>
      {mounted &&
        createPortal(<Sidebar open={open} onClose={onClose} />, document.body)}
    </>
  );
});
AkneprogrammetWrapper.displayName = 'AkneprogrammetWrapper';

const nav = tv({
  base: 'sticky -mb-10 top-0  lg:px-8 w-full',
  variants: {
    inverted: {
      true: 'bg-gray-100/50 text-gray-900 z-20',
      false: 'md:block backdrop-blur-sm text-white z-40',
    },
  },
  defaultVariants: {
    inverted: false,
  },
});

export const NavInverted = forwardRef<HTMLDivElement>((_props, ref) => (
  <nav ref={ref} className={nav({ inverted: true })}>
    <Wrapper inverted>
      <StartTreatmentButton />
    </Wrapper>
  </nav>
));
NavInverted.displayName = 'NavInverted';

export const StandardNav = forwardRef<HTMLDivElement>((_props, ref) => (
  <nav ref={ref} className={nav()}>
    <Wrapper inverted>
      <StartTreatmentButton />
    </Wrapper>
  </nav>
));
StandardNav.displayName = 'StandardNav';

export const AkneprogrammetStandardNav = forwardRef<HTMLDivElement>((_props, ref) => (
  <nav ref={ref} className={nav({ className: 'fixed' })}>
    <AkneprogrammetWrapper inverted>
      <SeekCareButton />
    </AkneprogrammetWrapper>
  </nav>
));

AkneprogrammetStandardNav.displayName = 'AkneprogrammetStandardNav';

export const AkneprogrammetNavInverted = forwardRef<HTMLDivElement>((_props, ref) => (
  <nav ref={ref} className={nav({ className: 'fixed ', inverted: true })}>
    <AkneprogrammetWrapper inverted>
      <SeekCareButton />
    </AkneprogrammetWrapper>
  </nav>
));
AkneprogrammetNavInverted.displayName = 'AkneprogrammetNavInverted';

export const Header: FC<{ heroRef: RefObject<HTMLElement> }> = ({ heroRef }) => {
  const heroNavRef = useRef<HTMLDivElement>(null);
  const bottomHeroNavRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const onScroll = () => {
      if (!heroRef.current) return;
      const rect = heroRef.current.getBoundingClientRect();

      const pos = rect.bottom - NAVBAR_HEIGHT_PX;
      if (bottomHeroNavRef.current) {
        if (rect.bottom - NAVBAR_HEIGHT_PX < 0) {
          bottomHeroNavRef.current.style.backgroundColor = '';
        } else {
          bottomHeroNavRef.current.style.backgroundColor = 'inherit';
        }
        if (pos < -NAVBAR_HEIGHT_PX) {
          bottomHeroNavRef.current.style.backdropFilter = `blur(4px)`;
        } else {
          bottomHeroNavRef.current.style.backdropFilter = `blur(0px)`;
        }
      }
      if (heroNavRef.current) {
        if (pos < 0) {
          heroNavRef.current.style.height = `${Math.max(rect.bottom, 0)}px`;
        } else {
          heroNavRef.current.style.height = `${NAVBAR_HEIGHT_PX}px`;
        }
      }
    };

    onScroll();

    window.addEventListener('scroll', onScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [heroRef]);

  return (
    <>
      <nav className={nav({})}>
        <Wrapper ref={heroNavRef}>
          <StartTreatmentButton className="md:invisible" />
        </Wrapper>
      </nav>
      <NavInverted />
    </>
  );
};

export const AkneprogrammetHeader: FC<{ heroRef: RefObject<HTMLElement> }> = ({
  heroRef,
}) => {
  const heroNavRef = useRef<HTMLDivElement>(null);
  const bottomHeroNavRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const onScroll = () => {
      if (!heroRef.current) return;
      const rect = heroRef.current.getBoundingClientRect();

      const pos = rect.bottom - NAVBAR_HEIGHT_PX;
      if (bottomHeroNavRef.current) {
        if (rect.bottom - NAVBAR_HEIGHT_PX < 0) {
          bottomHeroNavRef.current.style.backgroundColor = '';
        } else {
          bottomHeroNavRef.current.style.backgroundColor = 'inherit';
        }
        if (pos < -NAVBAR_HEIGHT_PX) {
          bottomHeroNavRef.current.style.backdropFilter = `blur(4px)`;
        } else {
          bottomHeroNavRef.current.style.backdropFilter = `blur(0px)`;
        }
      }
      if (heroNavRef.current) {
        if (pos < 0) {
          heroNavRef.current.style.height = `${Math.max(rect.bottom, 0)}px`;
        } else {
          heroNavRef.current.style.height = `${NAVBAR_HEIGHT_PX}px`;
        }
      }
    };

    onScroll();

    window.addEventListener('scroll', onScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [heroRef]);

  return (
    <>
      <nav className={nav({})}>
        <AkneprogrammetWrapper ref={heroNavRef}>
          <SeekCareButton />
        </AkneprogrammetWrapper>
      </nav>
      <AkneprogrammetNavInverted />
    </>
  );
};
